import '../css/main.css';
import Navbar from '../components/Navbar.js';
import Widget from '../components/widgets/Widget.js';
import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { updateSingInData } from "../store/signIn.reducer.js";

import SearchComponent from '../components/navigation/SearchComponent.js';
import MiniCart from '../components/navigation/MiniCart.js';
import FavoriteComponent from '../components/navigation/FavoriteComponent.js';

import i18n from "../i18n";
import CookiePopUp from "../components/CookiePopUp";
import JazFeed from '../components/widgets/JazFeed.js';

const Content = () => {

    const location = useLocation();
    const [siteState, setSiteState] = useState();
    const [navigations, setNavigations] = useState();
    const dispatch = useDispatch();
    const [isAdmin, setIsAdmin] = useState(false);

    const [showSearch, setShowSearch] = useState(false);
    const [showFavorits, setShowFavorits] = useState(false);
    const [showMiniCart, setShowMiniCart] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const searchRef = useRef(null);
    const [activeLanguage, setActiveLanguage] = useState(null);
    const [activeCountry, setActiveCountry] = useState(null);

    const [itemInCart, setItemInCart] = useState({ products: [] });
    const [favorites, setFavorites] = useState({products: []});

    const fetchCartData = () => {
        fetch('/Api/System/Shop/Session/Cart/GetBasicCartData')
            .then(response => response.json())
            .then(data => setItemInCart(data));
    };

    const fetchFavoriteData = () => {
        fetch('/Api/System/Shop/Session/WishList/GetWishlist')
            .then(response => response.json())
            .then(data => setFavorites(data));
    };


    const handleQuantityChange = async (id, type) => {
        const url = type === 'increase'
            ? '/Api/System/Shop/Session/Cart/AddProduct'
            : '/Api/System/Shop/Session/Cart/RemoveProduct';

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: 'productId=' + id + '&quantity=1'
            });

            if (!response.ok) {
                throw new Error(`Failed to update cart: ${response.statusText}`);
            }
            fetchCartData();
        } catch (error) {
            console.error('Error updating quantity:', error);
        }
    };

    const handleFavoriteQuantityChange = async (id, type) => {
        const url = type === 'increase'
            ? '/Api/System/Shop/Session/WishList/AddProduct'
            : '/Api/System/Shop/Session/WishList/RemoveProduct';

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: 'productId=' + id + '&quantity=1'
            });

            if (!response.ok) {
                throw new Error(`Failed to update cart: ${response.statusText}`);
            }
            fetchFavoriteData();
        } catch (error) {
            console.error('Error updating quantity:', error);
        }
    };

    useEffect(() => {
        fetchCartData();
        fetchFavoriteData();
    }, []);

    useEffect(() => {
        if (showMiniCart) {
            fetchCartData();
        }
    }, [showMiniCart]);

    const handleShowSearch = () => {
        setShowSearch(!showSearch);
    };

    const handleShowFavorits = () => {
        setShowFavorits(!showFavorits);
    };

    const handleShowMiniCart = () => {
        setShowMiniCart(!showMiniCart);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchRef.current && !searchRef.current.contains(event.target)) {
                setShowSearch(false);
            }
        };

        // Add event listener to detect clicks outside
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup event listener on unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (isMenuOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isMenuOpen]);

    useEffect(() => {
        // Get the full query string (e.g., ?name=John&age=30)
        const queryString = window.location.search;

        // Create a URLSearchParams object
        const urlParams = new URLSearchParams(queryString);
        urlParams.append('route', window.location.pathname);

        axios.get('/Api/Website/GetWebsiteSettings', { params: urlParams })
            .then(result => {
                var siteSettings = document.createElement("script");
                siteSettings.innerHTML = "var _websiteSettings = " + JSON.stringify(result.data.settings) + "; var _websiteTranslations = " + JSON.stringify(result.data.translations) + ";";
                document.body.appendChild(siteSettings);
                document.title = result.data.settings.activePage.title + " - " + result.data.settings.name;
                setSiteState(result.data);
                setActiveLanguage(result.data.activeLanguage);
                setActiveCountry(result.data.activeCountry);

                setIsAdmin(false);
                dispatch(updateSingInData(false));
                result.data.settings.activePage.resources.forEach(e => {
                    if (e.name === 'itcm-admin') {
                        setIsAdmin(true);
                        dispatch(updateSingInData(true));
                    }
                    if (e.type === 'JavaScript') {
                        var script = document.createElement('script');
                        script.type = 'text/javascript';
                        script.src = e.src;
                        if (e.target == 'body') {
                            document.body.appendChild(script);
                        } else {
                            document.head.appendChild(script);
                        }
                    } else if (e.type === 'Css') {
                        var style = document.createElement('link');
                        style.rel = 'stylesheet';
                        style.href = e.src;
                        document.head.appendChild(style)
                    } else if (e.type === 'Style') {
                        var styleObject = document.createElement("style");
                        styleObject.innerHTML = e.originalSrc
                        styleObject.id = e.name
                        document.head.appendChild(styleObject)
                    } else if (e.type === 'CustomJavaScript') {
                        var script = document.createElement('script');
                        script.type = 'text/javascript';
                        script.innerHTML = e.src;
                        if (e.target == 'body') {
                            document.body.appendChild(script);
                        } else {
                            document.head.appendChild(script);
                        }
                    } else if (e.type === 'Titel') {
                        document.title = e.src;
                    } else if (e.type === 'Html') {
                        if (e.target == 'body') {
                            document.body.insertAdjacentHTML('beforeend', e.src);
                        } else {
                            document.head.insertAdjacentHTML('beforeend', e.src);
                        }

                        const tempDiv = document.createElement("div");
                        tempDiv.innerHTML = e.src; // Parse the string as HTML

                        // Get all script elements
                        const scripts = tempDiv.querySelectorAll("script");

                        scripts.forEach((script) => {
                            const newScript = document.createElement("script");

                            if (script.src) {
                                // Handle external scripts (async load)
                                newScript.src = script.src;
                                newScript.async = script.async || false;
                                if (e.target == 'body') {
                                    document.body.appendChild(newScript);
                                } else {
                                    document.head.appendChild(newScript);
                                }
                            } else {
                                // Handle inline scripts (execute immediately)
                                newScript.textContent = script.textContent;
                                if (e.target == 'body') {
                                    document.body.appendChild(newScript);
                                } else {
                                    document.head.appendChild(newScript);
                                }
                            }
                        });

                    }
                    console.log('Added resource: ' + e.type + ' - ' + e.name + ' (' + e.src + ')');
                });
                console.log('Loaded page-resources');

                const navs = {};
                const keys = Object.keys(result.data.navigation.children);

                keys.forEach(elem => {
                    navs[elem] = result.data.navigation.children[elem];
                });

                setNavigations(Object.values(navs));
                console.log('Loaded navigations');

                console.log("Set languge: " + result.data.activeLanguage.shortCode);
                i18n.changeLanguage(result.data.activeLanguage.shortCode);

                Object.keys(result.data.translations).forEach((nsKey) => {
                    Object.keys(result.data.translations[nsKey]).forEach((transKey) => {
                        Object.keys(result.data.translations[nsKey][transKey]).forEach(langKey => {
                            i18n.addResourceBundle(langKey, 'translation', { [nsKey + '-' + transKey]: result.data.translations[nsKey][transKey][langKey] });
                        });
                    });
                });
                console.log('Loaded translations')

            }).catch(error => {
                if (error.response && error.response.status === 401) {
                    console.log("Unauthorized! Redirecting to login...");
                    // Redirect to login or show an error message
                    if(!error.response.data.forwardTo !== undefined) {
                        window.location.href = error.response.data.forwardTo;
                    }
                } else {
                    console.error("An error occurred:", error);
                }
            });
    }, [location]);

    const activeTemplate =
        siteState?.templates?.find(
            (template) => template.active && template.templateId === 5
        ) || null;

    if (siteState && navigations) {
        return (
            <>
                <Navbar
                    navigations={navigations}
                    templates={siteState?.templates}
                    handleShowSearch={handleShowSearch}
                    handleShowFavorits={handleShowFavorits}
                    handleShowMiniCart={handleShowMiniCart}
                    activeLanguage={activeLanguage}
                    activeCountry={activeCountry}
                    itemInCart={itemInCart}
                    favorite={favorites}
                />
                {showSearch && (
                    <div
                        ref={searchRef}
                        style={{
                            position: 'fixed',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            zIndex: '9999'
                        }}>
                        <SearchComponent handleShowSearch={handleShowSearch} />
                    </div>
                )}
                {showFavorits && (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}>
                        <FavoriteComponent setShowFavorits={setShowFavorits} showFavorits={showFavorits}  favorite={favorites} handleFavoriteQuantityChange={handleFavoriteQuantityChange}/>
                    </div>
                )}
                {showMiniCart && (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}>
                        <MiniCart
                            setShowMiniCart={setShowMiniCart}
                            showMiniCart={showMiniCart}
                            activeCountry={activeCountry}
                            activeLanguage={activeLanguage}
                            cart={itemInCart}
                            handleQuantityChange={handleQuantityChange}
                        />
                    </div>
                )}
                <div id="main" className="site-content" >
                    {isAdmin ? (
                        <div className="admin-overlay-padding"></div>
                    ) : (
                        <div></div>
                    )}
                    {siteState?.settings.activePage.widgets.map(widget => (
                        <div className={widget.fullWidth ? 'container-fluid' : 'container'}>
                            <div className='row'>
                                <div id={"widgetContainer-" + widget.id} className="widgetContainer">
                                    <Widget
                                        widget={widget}
                                        setShowMiniCart={setShowMiniCart}
                                        handleFavoriteQuantityChange={handleFavoriteQuantityChange}
                                        favorite={favorites}
                                        displayLg={widget.displayDeviceLg}
                                        displayMd={widget.displayDeviceMd}
                                        displaySm={widget.displayDeviceSm}
                                        displayXs={widget.displayDeviceXs}
                                    >
                                    </Widget>
                                </div>
                            </div>
                        </div>
                    ))}
                    <CookiePopUp />
                </div>
                <>
                    {activeTemplate ? (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: activeTemplate.data,
                            }}
                        />
                    ) : null}
                </>
            </>
        );
    }
}

export default Content;
